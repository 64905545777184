<!-- 内容-文章管理 -->
<template>
    <div class="ditchbox">
        <div class="management">
            <div class="global-search-single">
                <div class="single-left" v-if="hasPerm(['article.store'])">
                    <el-button type="primary" @click="handleAddArticle">添加文章</el-button>
                </div>
                <div class="single-right">
                    <div class="search-item">
                        <label class="search-label">分类：</label>
                        <el-cascader filterable v-model="search.article_cat_id" ref="cascader" :options="optionparent"
                            clearable @change="changeCascader"
                            :props="{ value: 'id', label: 'name', children: 'children', checkStrictly: true, emitPath: false, leaf: 'hasChildren' }">
                        </el-cascader>
                    </div>
                    <div class="search-item">
                        <el-input v-model="search.catIdInfo.value" placeholder="文章标题"></el-input>
                    </div>
                    <el-button type="primary" @click="wzsrach">搜索</el-button>
                    <el-button @click="closeSearch">重置条件</el-button>
                </div>
            </div>

            <div class="essay_table">
                <el-table :data="essayData" style="width:100%">
                    <el-table-column label="排序" prop="sort"></el-table-column>
                    <el-table-column label="文章标题" min-width="200" prop="title"></el-table-column>
                    <el-table-column label="分类" min-width="200">
                        <template slot-scope="scope">
                            <span>{{ (scope.row.article_cat && scope.row.article_cat.name) || '暂无分类' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" min-width="200">
                        <template slot-scope="scope">
                            <span>{{ common_info['content.article.type.list'][scope.row.type] }}</span>
                            <span class="type-btn" @click="handleTypeModify(scope.row)">编辑</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="作者" min-width="200" prop="author"></el-table-column>
                    <el-table-column label="时间" min-width="200" prop="created_at"></el-table-column>
                    <!-- <el-table-column label="状态" min-width="200" prop="is_show">
                        <template slot-scope="scope">
                            <span>{{ scope.row.is_show == 0 ? "显示" : '不显示' }}</span>
                        </template>
                                        </el-table-column> -->
                    <!-- <el-table-column label="置顶" min-width="200" prop="title"></el-table-column> -->
                    <el-table-column label="操作" min-width="200" align="center">
                        <template slot-scope="scope">
                            <div class="caozuo">
                                <a href="javascript:void(0);" @click="handleModify(scope.row)"
                                    v-if="hasPerm(['article.update'])">编辑</a>
                                <a href="javascript:void(0);" @click="handleDelete(scope.row)"
                                    v-if="hasPerm(['article.delete'])">删除</a>
                            </div>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div class="empty_data">
                            <p>暂无数据</p>
                        </div>
                    </template>
                </el-table>
            </div>
        </div>
        <!-- dialog -->
        <el-dialog title="文章类型" class="balancetc" :visible.sync="typeDialog" width="700px" :close-on-click-modal="false"
            :show-close="false">
            <div class="rbalancebox">
                <el-form :model="typeForm" ref="addressForm" label-width="80px">
                    <ul>
                        <li class="clearfloat balanceformitem">
                            <el-form-item prop="type">
                                <div class="orderfirstinput">
                                    <el-radio-group v-model="typeForm.type">
                                        <el-radio v-for="(item,index) in typeList" :key="index" :label="Number(item.value)">{{ item.label
                                        }}</el-radio>
                                    </el-radio-group>
                                </div>
                            </el-form-item>
                        </li>
                    </ul>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取 消</el-button>
                <el-button @click="handleSubmit" type="primary">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { Message } from 'element-ui';
import common from '../../../common/common';
import { mapState } from 'vuex';
export default {
    data() {
        return {
            search: {
                article_cat_id: "",//分类id
                // title: "",//文章标题
                catIdInfo: {
                    option: "title", //选择下来key值
                    value: "",//输入的值
                    type: 'selectSearch',
                    op: "search"
                }
            },
            searchObj: {
                search: [],
                order: [
                    {
                        key: 'sort',
                        value: 'asc'
                    },
                ]
            },
			pageInfo: {
			    page: 1,
			    limit: 10
			},//表格翻页信息
            essayData: [],
            optionparent: [],//分类cascader
            typeDialog: false,
            typeForm: {
                type: 0,
                id: ""
            }
        }
    },
    beforeCreate() {
        // 清空commonInfo缓存
        this.$store.commit("commonInfo", "");
    },
    created() {
        //请求全局配置资源(user.level_list,user.is_freeze)
        common.getCommonInfo(this, { keys: Array.of("content.article.type.list") });
        this.getClassArticleList();
        this.getContentArticleList();
    },
    methods: {
        // 获取分类列表
        async getClassArticleList() {
            const { data: { list } } = await this.$get(this.$apis.contentArticleClassApi);
            const result = common.changeCascaderSource(list, true);
            this.$set(this, "optionparent", result);
        },
		wzsrach() {
		    this.$set(this, 'searchObj', { ...this.searchObj, ...this.pageInfo });
		    this.$set(this.searchObj, 'search', common.handleSearch(this.search, 'search'));
			this.pageInfo.page= 1;
		    // 刷新table
		    this.getContentArticleList();
		},
        // 获取文章列表
        async getContentArticleList() {
            this.$set(this.searchObj, 'search', common.handleSearch(this.search));
            const { data: { list }, code, message } = await this.$get(this.$apis.contentArticleApi, this.searchObj);
            if (code != 200) {
                Message.error(message)
            }
            this.$set(this, 'essayData', list);
        },
        changeCascader(e) {
            if (!e) this.search.article_cat_id = ''
        },
        closeSearch() {
            this.search.article_cat_id = "";//分类id
            this.search.catIdInfo.value = "";//分类id
            this.getContentArticleList()
        },
        // 添加文章
        handleAddArticle() {
            // 跳转
            this.$router.push("/channel/content/article_edit");
        },
        // 编辑
        handleModify({ id }) {
            this.$router.push({ path: "/channel/content/article_edit", query: { id } });
        },
        // 删除文章
        handleDelete(row) {
            this.$confirm(`确定要将文章【${row.title}】永久删除吗?`, '警告', {
                type: 'warning'
            }).then(() => {
                this.$delete(`${this.$apis.contentArticleApi}/${row.id}`).then(res => {
                    Message[(() => res.code == 200 ? 'success' : 'error')()](`${res.code == 200 ? '删除文章成功!' : res.message}`);
                    this.getContentArticleList();
                })
            }).catch(err => {
                Message[(() => err != 'cancel' ? 'error' : 'info')()](`${err != 'cancel' ? `${err}` : '已取消删除!'}`);
            })
        },
        // 修改类型
        handleTypeModify(row) {
            this.$set(this.typeForm, 'type', row.type);
            this.$set(this.typeForm, 'id', row.id);
            this.typeDialog = true;
        },
        handleCancel() {
            this.typeDialog = false;
        },
        handleSubmit() {
            const { id, type } = this.typeForm;
            this.$put(`${this.$apis.articleTypeApi}/${id}`, { type }).then(res => {
                if (res.code == 200) {
                    Message.success("编辑文章类型状态成功!");
                    this.handleCancel();
                    this.getContentArticleList();
                } else {
                    Message.error(res.message);
                }
            })
        }
    },
    computed: {
        ...mapState(['common_info']),
        common_info: {
            get() {
                return this.$store.state.common_info
            },
            set(value) {
                this.$store.commit("commonInfo", value)
            },
        },
        typeList() {
            return this.common.mapSource2List(this.common_info['content.article.type.list'] || {})
        }

    }
}
</script>

<style scoped lang="less">
@import url("css/ditch.css");

.essay_table {
    padding-top: 20px;
}

.essay_head_input {
    margin-right: 10px;
}

.caozuo {
    border-right: none;
}

.type-btn {
    display: inline-block;
    width: auto;
    padding: 0 3px;
    border-radius: 2px;
    background-color: var(--fontColor);
    color: #fff;
    font-size: 12px;
    margin: 0 2px;
    cursor: pointer;
}

/deep/.el-dialog__body {
    & .rbalancebox {
        width: 100%;
        height: 100%;

        & .el-form {
            width: 100%;
            height: 100%;

            & .balanceformitem {
                height: 100%;

                & .el-form-item {
                    margin: 0;

                    & .el-form-item__content {
                        margin: 0 !important;

                        & .el-radio-group {
                            display: flex;
                            flex-wrap: wrap;
                            align-content: space-between;
                            padding: 20px;
                            height: 60px;
                        }
                    }
                }
            }
        }
    }
}

.dialog-footer .addressqr:hover {
    color: #fff !important;
}

.orderfirstinput {
    width: 100%;
}
</style>